import * as THREE from "three";
import { Clock } from "three";
import fragment from "./shader/fragment.glsl";
import vertex from "./shader/vertex.glsl";
// let OrbitControls = require("three-orbit-controls")(THREE);

import skullModel from './skull.glb'

import {GLTFLoader} from "./three-modules/loaders/GLTFLoader.js";

export default class Sketch {
  constructor(options) {
    

    // Boilerplate mainly
    this.container = options.dom;
    this.baseSetup();
    this.mouseMove();

    this.loader = new GLTFLoader();
    this.loader.load(  `${window.location + skullModel}`, (object) => {
      object.scene.traverse(child => {
        if(child.isMesh) {
          this.skull = child;
          this.addObjects();
          this.resize();
          this.render();
          this.resize();
        }
      }) 
      
    });
  }


  resize() {
    window.addEventListener("resize", () => {
      this.width = this.container.offsetWidth;
      this.height = this.container.offsetHeight;
      this.renderer.setSize(this.width, this.height);
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
    });
  }

  mouseMove() {
    this.mouse = { x:0, y:0 }
    window.addEventListener("mousemove", event => {
      this.mouse.x = 2 * (event.clientX / window.innerWidth - .5);
      this.mouse.y = 2 * (event.clientY / window.innerHeight - .5);
    })
  }

  // Add the objects into the scene
  
  addObjects() {
    this.setupMaterial();
    this.geometry = this.skull.geometry;
    this.geometry.center();
    this.geometry.rotateX(.96);
    this.skullMesh = new THREE.Mesh(this.geometry, this.material);
    this.skullMesh.scale.set(.5,.5,.5)
    this.scene.add(this.skullMesh);
  }

  // Sets up a material

  setupMaterial() {
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
    vertexShader: vertex,
      fragmentShader: fragment
    });
  }

  



  // Update and render

  render() {
    this.material.uniforms.time.value = this.clock.getElapsedTime();  
    
    this.skullMesh.position.y = Math.sin(this.clock.getElapsedTime()) *.0118;

    this.skullMesh.rotation.y = THREE.MathUtils.lerp(
      this.skullMesh.rotation.y,
      -this.mouse.x * .5,
    .3);
    this.skullMesh.rotation.x = THREE.MathUtils.lerp(
      this.skullMesh.rotation.x,
      -this.mouse.y * .5,
    .3);


    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }


  // Setup the scene. 
  // Usually the same with exception to camera setup
  
  baseSetup() {
    this.scene = new THREE.Scene();

    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x000000, 0.);
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      25,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );

    
    this.camera.position.set(0, 0, 7);
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.clock = new Clock();
  }
}

new Sketch({
  dom: document.getElementById("container")
});
